import React from 'react';

const UnderConstraction = () => {
    return (
        <div className="under">
            <img className="imgc" src="medium.png"/>

            <h1>
                Сайт у розробці
            </h1>
            <h1>
                The site is under development
            </h1>
        </div>

    );
};

export {UnderConstraction};